import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './options.css'
// fake data generator
const getItems = (count, offset, item) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `${offset}`,
        item: `${item}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 20,
    // change background colour if dragging
    background: isDragging ? "rgba(0,155,190,0.3)" : "transparent",
    color: 'rgba(107, 11, 10)',
    fontFamily: 'luckiestGuy',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "transparent" : "transparent",
    color: 'rgba(107, 11, 10)',
    fontFamily: 'luckiestGuy',
    fontSize: '1.2vw',
    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "transparent" : "transparent",
    padding: grid,
});

const getListStyle2 = isDraggingOver => ({
    background: isDraggingOver ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
    padding: grid,
});
const getListStyle3 = isDraggingOver => ({
    background: isDraggingOver ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
    padding: grid,
});

const getListStyle4 = isDraggingOver => ({
    background: isDraggingOver ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
    padding: grid,
});
const getListStyle5 = isDraggingOver => ({
    background: isDraggingOver ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
    padding: grid,
});
const getListStyle6 = isDraggingOver => ({
    background: isDraggingOver ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)",
    padding: grid,
});
let options = [{ content: 'Removes waste products and extra fluid from the body, which are excreted in the urine1', id: '1' }, { content: 'by producing the hormone renin, which directly and indirectly causes blood vessels to constrict, and by modulating fluid levels1,2', id: '2' }, { content: 'by reabsorbing or excreting acids and bases1', id: '3' }, { content: 'Produces the erythropoietin that stimulates the bone marrow to produce red blood cells1', id: '4' }, { content: 'Keeps bones healthy by converting vitamin D to its active form and by balancing levels of calcium and phosphorus1', id: '5' }]
let rightOptions = ['Removes waste products and extra fluid from the body, which are excreted in the urine1', 'by producing the hormone renin, which directly and indirectly causes blood vessels to constrict, and by modulating fluid levels1,2', 'by reabsorbing or excreting acids and bases1', 'Produces the erythropoietin that stimulates the bone marrow to produce red blood cells1', 'Keeps bones healthy by converting vitamin D to its active form and by balancing levels of calcium and phosphorus1']
function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}
export function Game(props) {
    useEffect(() => {
        options = shuffle(options)
    })
    const [state, setState] = useState([getItems(1, options[0].content, options[0].id), getItems(1, options[1].content, options[1].id), getItems(1, options[2].content, options[2].id), getItems(1, options[3].content, options[3].id), getItems(1, options[4].content, options[4].id)]);
    const [state2, setState2] = useState([getItems(1, '-')]);
    const [showItem1, setShowItem1] = useState(false);
    const [showItem2, setShowItem2] = useState(false);
    const [showItem3, setShowItem3] = useState(false);
    const [showItem4, setShowItem4] = useState(false);
    const [showItem5, setShowItem5] = useState(false);
    const [wrongTries, setWrongTries] = useState(0);
    const [answered, setAnswered] = useState(0);
    useEffect(() => {
        if (answered === 5) { 
            props.onFinish(wrongTries)
            // alert('done' + ' - wrong tries : ' + wrongTries)
        }
    }, [answered])
    
    function onDragEnd(result, provided) {
        console.log(provided);
        const { source, destination } = result;
        console.log(result);
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        console.log('s: ', sInd, 'd : ', dInd);
        if (sInd === dInd) {
            // const items = reorder(state[sInd], source.index, destination.index);
            // const newState = [...state];
            // newState[sInd] = items;
            // setState(newState);
        } else if (dInd === 14 && state[sInd][0].item === '1') {
            let temp = state[sInd]
            console.log(temp[0].content);
            setState(state.filter(el => el !== state[sInd]));
            setState2([temp])
            setShowItem1(true)
            let tempAnswered = answered + 1
            setAnswered(tempAnswered)
        }
        else if (dInd === 5 && state[sInd][0].item === '2') {
            let temp = state[sInd]
            console.log(temp[0].content);
            setState(state.filter(el => el !== state[sInd]));
            setState2([temp])
            setShowItem2(true)
            let tempAnswered = answered + 1
            setAnswered(tempAnswered)
        }
        else if (dInd === 6 && state[sInd][0].item === '3') {
            let temp = state[sInd]
            console.log(temp[0].content);
            setState(state.filter(el => el !== state[sInd]));
            setState2([temp])
            setShowItem3(true)
            let tempAnswered = answered + 1
            setAnswered(tempAnswered)
        }
        else if (dInd === 7 && state[sInd][0].item === '4') {
            let temp = state[sInd]
            console.log(temp[0].content);
            setState(state.filter(el => el !== state[sInd]));
            setState2([temp])
            setShowItem4(true)
            let tempAnswered = answered + 1
            setAnswered(tempAnswered)
        }
        else if (dInd === 8 && state[sInd][0].item === '5') {
            let temp = state[sInd]
            console.log(temp[0].content);
            setState(state.filter(el => el !== state[sInd]));
            setState2([temp])
            setShowItem5(true)
            let tempAnswered = answered + 1
            setAnswered(tempAnswered)
        }
        else {
            let temp = wrongTries + 1
            setWrongTries(temp)
            // alert(wrongTries)
        }
    }

    return (
        <div className="dndContainer">

            <div style={{}}>

                <DragDropContext onDragEnd={onDragEnd}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%', width: '100%' }}>
                        <Droppable

                            key={14} droppableId={`${14}`}>

                            {(provided, snapshot) => (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div
                                        className="first"
                                        ref={provided.innerRef}
                                        style={getListStyle2(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        <div className='img1' />
                                        {state2.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle2(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div
                                                            className="firstText"
                                                            style={{
                                                                color: !showItem1 && 'white',
                                                                fontFamily: !showItem1 && 'arial'
                                                            }}
                                                        >
                                                            {showItem1 ? rightOptions[0] : 'DRAG AND DROP THE CORRECT DEFINITION'}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>



                        <Droppable
                            key={5} droppableId={`${5}`}>
                            {(provided, snapshot) => (
                                <div
                                    className="second"
                                    ref={provided.innerRef}
                                    style={getListStyle3(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    <div className='img5' />

                                    {state2.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={item.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle2(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        className="secondText"
                                                        style={{
                                                            color: !showItem2 && 'white',
                                                            fontFamily: !showItem2 && 'arial',
                                                        }}
                                                    >
                                                        {showItem2 ? rightOptions[1] : 'DRAG AND DROP THE CORRECT DEFINITION'}

                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <Droppable
                            key={6} droppableId={`${6}`}>
                            {(provided, snapshot) => (
                                <div
                                    className="third"
                                    ref={provided.innerRef}
                                    style={getListStyle4(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    <div className='img3' />

                                    {state2.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={item.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle2(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        className="thirdText"
                                                        style={{
                                                            color: !showItem3 && 'white',
                                                            fontFamily: !showItem3 && 'arial',

                                                        }}
                                                    >
                                                        {showItem3 ? rightOptions[2] : 'DRAG AND DROP THE CORRECT DEFINITION'}

                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>


                        <Droppable

                            key={7} droppableId={`${7}`}>
                            {(provided, snapshot) => (
                                <div
                                    className="fourth"
                                    ref={provided.innerRef}
                                    style={getListStyle5(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    <div className='img2' />

                                    {state2.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={item.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle2(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        className="fourthText"
                                                        style={{
                                                            color: !showItem4 && 'white',
                                                            fontFamily: !showItem4 && 'arial',
                                                        }}
                                                    >
                                                        {showItem4 ? rightOptions[3] : 'DRAG AND DROP THE CORRECT DEFINITION'}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <Droppable

                            key={8} droppableId={`${8}`}>
                            {(provided, snapshot) => (
                                <div
                                    className="fifth"
                                    ref={provided.innerRef}
                                    style={getListStyle6(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    <div className='img4' />

                                    {state2.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={item.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle2(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        className="fifthText"
                                                        style={{
                                                            color: !showItem5 && 'white',
                                                            fontFamily: !showItem5 && 'arial',
                                                        }}
                                                    >
                                                        {showItem5 ? rightOptions[4] : 'DRAG AND DROP THE CORRECT DEFINITION'}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div
                        className="options"
                    >

                        {state.map((el, ind) => {
                            return (
                                <Droppable key={ind} droppableId={`${ind}`}>
                                    {(provided, snapshot) => (
                                        <div
                                            className={`option${ind + 1}`}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {el.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={item.id}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="optionsText"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <div className={`num${parseInt(ind) + 1}`} />
                                                            <div
                                                                style={{
                                                                    // display: "flex",
                                                                    // justifyContent: "flex-start",
                                                                    width: '21vw',
                                                                }}
                                                            >

                                                                {item.content}
                                                                {/* <button
                                                            type="button"
                                                            onClick={() => {
                                                                const newState = [...state];
                                                                newState[ind].splice(index, 1);
                                                                setState(
                                                                    newState.filter(group => group.length)
                                                                );
                                                            }}
                                                        >
                                                            delete
                                                        </button> */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )
                        })}
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<QuoteApp />, rootElement);
