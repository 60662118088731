import React, { useState } from "react";
import "./App.css";
import { Game } from "./components/Game";
import Finished from "./components/Finished";
import SelectTeam from "./components/SelectTeam";
import { collection } from "./firebase/firebaseconfig";

function App() {
  const [teamSelected, setTeamSelected] = useState(0);
  const [finished, setFinished] = useState(false);

  function selectTeamHandler(team) {
    setTeamSelected(team);
  }

  async function finishedHandler(wrongTries) {
    const score = 500 - wrongTries * 100;
    const teamRef = collection.doc("teams");
    const teams = await teamRef.get();
    let teamsData = teams.data();
    if (teamsData[`t${teamSelected}`] === undefined) {
      teamsData[`t${teamSelected}`] = { g4: [] };
    } else if (teamsData[`t${teamSelected}`].g4 === undefined) {
      teamsData[`t${teamSelected}`].g4 = [];
    }
    teamsData[`t${teamSelected}`].g4 = [
      ...teamsData[`t${teamSelected}`].g4,
      {
        score,
        timestamp: Date.now(),
      },
    ];
    await teamRef.set(teamsData);
    setFinished(true);
  }

  function closeFinishMessageHandler() {
    setFinished(false);
    setTeamSelected(0);
    window.location.reload();
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="draggables">
          {teamSelected === 0 && !finished && (
            <SelectTeam onSelect={(team) => selectTeamHandler(team)} />
          )}
          {teamSelected > 0 && !finished && <Game onFinish={finishedHandler} />}
          {finished && <Finished onClose={() => closeFinishMessageHandler()} />}
          {/* <Drop /> */}
        </div>
      </header>
    </div>
  );
}

export default App;
